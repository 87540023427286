import axios from 'axios';

const api = axios.create({
    // baseURL: 'https://api.e-assessoriafinanceira.com.br/'

    //New
    baseURL: 'https://api.assessoriacartoes.com.br/'
    //baseURL: 'http://localhost:5000'
});

export default api;